//
// Pages: Invoice
//

.invoice {
  background-color: $white;
  border: 1px solid $card-border-color;
  position: relative;
}

.invoice-title {
  margin-top: 0;
}

@include dark-mode () {
  .invoice {
    background-color: $dark;
  }
}
