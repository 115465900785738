//
// Mixins: Touch Support
//

@mixin on-touch-device {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

//
