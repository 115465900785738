//
// Core: Variables for Dark Mode
//

// COLORS
// --------------------------------------------------------

// stylelint-disable
// Gray color will be default in dark mode
$white-alt:    $white !default;
$gray-100-alt: $gray-100 !default;
$gray-200-alt: $gray-200 !default;
$gray-300-alt: $gray-300 !default;
$gray-400-alt: $gray-400 !default;
$gray-500-alt: $gray-500 !default;
$gray-600-alt: $gray-600 !default;
$gray-700-alt: $gray-700 !default;
$gray-800-alt: $gray-800 !default;
$gray-900-alt: $gray-900 !default;
$black-alt:    $black !default;

$grays-alt: () !default;
$grays-alt: map-merge((
  "100": $gray-100-alt,
  "200": $gray-200-alt,
  "300": $gray-300-alt,
  "400": $gray-400-alt,
  "500": $gray-500-alt,
  "600": $gray-600-alt,
  "700": $gray-700-alt,
  "800": $gray-800-alt,
  "900": $gray-900-alt
), $grays-alt);

// Below colors from bootwatch darkly
$blue-alt:    #3f6791 !default;
$indigo-alt:  #6610f2 !default;
$purple-alt:  #6f42c1 !default;
$pink-alt:    #e83e8c !default;
$red-alt:     #e74c3c !default;
$orange-alt:  #fd7e14 !default;
$yellow-alt:  #f39c12 !default;
$green-alt:   #00bc8c !default;
$teal-alt:    #20c997 !default;
$cyan-alt:    #3498db !default;

// by darken function
$lightblue-alt: lighten(#3c8dbc, 20%) !default;
$navy-alt: lighten(#001f3f, 5%) !default;
$olive-alt: lighten(#3d9970, 20%) !default;
$lime-alt: lighten(#01ff70, 20%) !default;
$fuchsia-alt: lighten(#f012be, 20%) !default;
$maroon-alt: lighten(#d81b60, 20%) !default;
$gray-x-light-alt: lighten(#d2d6de, 20%) !default;

$colors-alt: () !default;
$colors-alt: map-merge((
  "blue":       $blue-alt,
  "indigo":     $indigo-alt,
  "purple":     $purple-alt,
  "pink":       $pink-alt,
  "red":        $red-alt,
  "orange":     $orange-alt,
  "yellow":     $yellow-alt,
  "green":      $green-alt,
  "teal":       $teal-alt,
  "cyan":       $cyan-alt,
  "white":      $white-alt,
  "gray":       $gray-600-alt,
  "gray-dark":  $gray-800-alt
), $colors-alt);

$primary-alt:       $blue-alt !default;
$secondary-alt:     $gray-600-alt !default;
$success-alt:       $green-alt !default;
$info-alt:          $cyan-alt !default;
$warning-alt:       $yellow-alt !default;
$danger-alt:        $red-alt !default;
$light-alt:         $gray-100-alt !default;
$dark-alt:          $gray-800-alt !default;

$theme-colors-alt: () !default;
$theme-colors-alt: map-merge((
  "primary":    $primary-alt,
  "secondary":  $secondary-alt,
  "success":    $success-alt,
  "info":       $info-alt,
  "warning":    $warning-alt,
  "danger":     $danger-alt,
  "light":      $light-alt,
  "dark":       $dark-alt
), $theme-colors-alt);

$colors-alt: map-merge(
  (
    "lightblue": $lightblue-alt,
    "navy": $navy-alt,
    "olive": $olive-alt,
    "lime": $lime-alt,
    "fuchsia": $fuchsia-alt,
    "maroon": $maroon-alt,
  ),
  $colors-alt
);
// stylelint-enable

//
