//
// Mixins: Dark Mode Controll
//

@mixin dark-mode {
  @if $enable-dark-mode {
    .dark-mode {
      @content;
    }
  }
}
