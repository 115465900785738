/*
Los colores del brand estan como 'primary' , 'primary-dark' , 'secondary' ,'tertiary'
*/
:root {
    --blue: #0077ba;
    --blue-dark: #005b97;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #E04A4A;
    --orange: #fd7e14;
    --yellow: #f29525;
    --green: #4FB62B;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #999999;/*6c757d*/
    --gray-light: #BABABA;
    --gray-medium: #eaeaea;
    --gray-dark: #343a40;
    --primary: #0147BA;
    --primary-op50: #0148ba80;
    --primary-op80: #0148bac7;
    --primary-dark: #013894;
    --primary-darker: #013081;
    --secondary: #666666;/*6c757d*/
    --tertiary: #f29525;
    --success: #4FB62B;
    --info: #17a2b8;
    --warning: #f29525;
    --danger: #E04A4A;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Montserrat" , "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }

  body {
    font-family: "Montserrat" , "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
