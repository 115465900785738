//Angular Material section

/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$telepagos-typography: mat.define-typography-config(
  $font-family: 'Poppins',
);

$telepagos-primary: (
 50: var(--primary),
 100: var(--primary),
 200: var(--primary),
 300: var(--primary),
 400: var(--primary),
 500: var(--primary),
 600: var(--primary),
 700: var(--primary),
 800: var(--primary),
 900: var(--primary),

 // ... continues to 900
 contrast: (
   50: rgba(white, 0.87),
   100: rgba(white, 0.87),
   200: rgba(white, 0.87),
   300: rgba(white, 0.87),
   400: rgba(white, 0.87),
   500: rgba(white, 0.87),
   600: rgba(white, 0.87),
   700: rgba(white, 0.87),
   800: rgba(white, 0.87),
   900: rgba(white, 0.87),
   // ... continues to 900
 )
);

$telepagos-accent: (
 50: var(--tertiary),
 100: var(--tertiary),
 200: var(--tertiary),
 300: var(--tertiary),
 400: var(--tertiary),
 500: var(--tertiary),
 600: var(--tertiary),
 700: var(--tertiary),
 800: var(--tertiary),
 900: var(--tertiary),

 // ... continues to 900
 contrast: (
   50: rgba(white, 0.87),
   100: rgba(white, 0.87),
   200: rgba(white, 0.87),
   300: rgba(white, 0.87),
   400: rgba(white, 0.87),
   500: rgba(white, 0.87),
   600: rgba(white, 0.87),
   700: rgba(white, 0.87),
   800: rgba(white, 0.87),
   900: rgba(white, 0.87),
   // ... continues to 900
 )
);

$telepagos-admin-primary: mat.define-palette($telepagos-primary);
$telepagos-admin-accent: mat.define-palette($telepagos-accent);

// The warn palette is optional (defaults to red).
$telepagos-admin-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".


$telepagos-admin-theme: mat.define-light-theme((
  color: (
    primary: $telepagos-admin-primary,
    accent: $telepagos-admin-accent,
    warn: $telepagos-admin-warn,
  ),
  typography: $telepagos-typography
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($telepagos-admin-theme);

//End Angular Material section

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

// @import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
// @import "~bootstrap/dist/css/bootstrap.min.css";
// @import '~admin-lte/dist/css/adminlte.css';

@import 'ngx-toastr/toastr';

body {
    width: 100vw;
    height: 100vh;
    background-color: #f5f5f5;
    font-size: 0.8rem !important;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.user-menu > .nav-link {
    cursor: pointer;
}

.user-menu > .nav-link:after {
    content: none;
}

.user-menu > .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    width: 280px;
}

.user-menu > .dropdown-menu,
.user-menu > .dropdown-menu > .user-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center;
}

.user-menu > .dropdown-menu > li.user-header > img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.user-menu > .dropdown-menu > li.user-header > p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
}

.user-menu > .dropdown-menu > li.user-header > p > small {
    display: block;
    font-size: 12px;
}

.user-menu > .dropdown-menu > .user-body {
    border-bottom: 1px solid #495057;
    border-top: 1px solid #dee2e6;
    padding: 15px;
}

.user-menu > .dropdown-menu > .user-body::after {
    display: block;
    clear: both;
    content: '';
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-body a {
        background: #ffffff !important;
        color: #495057 !important;
    }
}

.user-menu > .dropdown-menu > .user-footer {
    background-color: #f8f9fa;
    padding: 10px;
}

.user-menu > .dropdown-menu > .user-footer::after {
    display: block;
    clear: both;
    content: '';
}

.user-menu > .dropdown-menu > .user-footer .btn-default {
    color: #6c757d;
}

@media (min-width: 576px) {
    .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
        background-color: #f8f9fa;
    }
}

.user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2.1rem;
    margin-right: 10px;
    margin-top: -2px;
    width: 2.1rem;
}

@media (min-width: 576px) {
    .user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-right: 0.4rem;
        margin-top: -8px;
    }
}

.user-menu .user-image {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 0;
    margin-left: -8px;
}

.content {
    padding: 0 0.5rem;
}

.sidebar-collapse .brand-image-large {
    display: none ;
}
.sidebar-open .brand-image-small {
    display: none ;
}
.main-sidebar:hover .brand-image-small{
    display: none ;

}
.main-sidebar:hover .brand-image-large{
    display: inline ;
}

//Para corregir template

.navbar-dark{
    border-width: 0 !important;
}

//Para proposito general

.cursor-pointer{
    cursor: pointer;
}

//media queries para botones block

@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;

    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }
}

// Estilos app telepagos v3

$border-radius : 0.3125rem;
$box-shadow : 0px 0px 30px rgba(0, 0, 0, 0.07);
$gray-filter: invert(85%) sepia(13%) saturate(13%) hue-rotate(34deg) brightness(89%) contrast(83%);
$primary-filter: invert(19%) sepia(90%) saturate(2358%) hue-rotate(210deg) brightness(90%) contrast(108%);

.content-wrapper{
  background-color: unset !important;
}

.card , .small-box {
    border-radius: 0.3125rem;
    box-shadow: $box-shadow;
}

.small-box .small-box-footer{
    border-radius:  0 0 $border-radius $border-radius;
}

.btn {
    border-radius: 0.3125rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
}

.btn.btn-padded {
  padding-left: 2.6rem;
  padding-right: 2.6rem;
}

.custom-dropdown{
  padding: 1.8rem;
  .option{
    font-weight: 600 !important;
    font-size: 0.9rem;
  }
}

.user-menu>.dropdown-menu  {
  border-radius: 0 0 0 $border-radius !important;
  box-shadow: $box-shadow;
  width: 19rem;
  li{
    border-radius: 0 0 0 0.23rem !important;
    overflow: hidden;
  }
}

.dropdown-menu {
  top: 2.5rem;
  box-shadow: $box-shadow;
  &.dropdown-menu-right{
    right: -0.56rem;
  }
}
[class*=sidebar-light-] .sidebar a {
  color: var(--gray-light) !important;
  p{
    color: var(--primary) !important ;
  }
}
.nav-sidebar .nav-link.active {
  background-color: #ffffff !important ;
  i{
    color: var(--primary) !important ;
  }
  box-shadow: none !important;
}

.separation-line {
  height: 1px;

  &.white{
    background-color: #fff;
    opacity: .3;
  }
  &.secondary{
    background-color: var(--secondary);
    opacity: .3;
  }
}

.sidebar{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500
}

.plataforma-web{
  color: var(--secondary);
  display: none;
  overflow: hidden !important;
  white-space: nowrap !important;
  ul{
    display: block;
    padding-left: 1rem;
    padding-bottom: 0.1rem;
  }
}
.sidebar-open{
  .plataforma-web{
    display: block;
  }
}
.sidebar-collapse{
  .main-sidebar:hover{
    .plataforma-web{
      display: block;
    }
  }
}

.navbar-white {
  background-color: unset;
  color: var(--primary) !important;
}
.navbar-light .navbar-nav .nav-link {
  color: var(--primary) !important;
}
.main-footer {
  background-color: unset;
}

.main-sidebar{
  box-shadow: $box-shadow;
  .nav-link{
    &.active{
      .sidebar-icon-custom {
        background-color: var(--primary);
      }
    }
  }

}

// Sidebar original TP

// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link,
// [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link {
//   background-color: unset;
// }
// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link:not(.active) i,
// [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link:not(.active) i{
//   color: var(--primary);
// }
// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link:not(.active),
// [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link:not(.active) {
//   .sidebar-icon-custom{
//     background-color: var(--primary );
//   }
// }

//Sidebar sin iconos y estatico

// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link{
//   background-color: var(--secondary);
// }
// [class*="sidebar-light-"] .nav-sidebar > .nav-item{
//   border-radius: 0px;
// }

// [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link.active div.icon{
//   background-color: var(--primary);
// }
// // [class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link:not(.active),
// // [class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link:not(.active) {
// //   .sidebar-icon-custom{
// //     background-color: var(--primary );
// //   }
// // }

.btn-anchor{
  background-color: unset;
  border: unset;
  &-primary{
    color: var(--primary);
    &:hover{
      color: var(--primary-dark)
    }

    &[disabled = true],&:disabled{
      color: var(--secondary);
      opacity: 0.7;
      cursor: not-allowed;
      &:hover{
        color: var(--secondary);
      }
    }

  }
}

.btn-paginacion{
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
  border: none;
  background-color: #EAEAEA;
}

.fix-padding{
  padding: .75rem 1.25rem;
}
.fix-padding-left{
  padding-left: 1.25rem;
}

.bg-white{
  background-color: #ffffff;
}

.height-full{
    min-height: calc(100vh - (3.5rem + 1px) - (3.5rem + 1px));
}

.sidebar-icon-position{
  transform: translate(.2rem,.2rem);
  position: absolute;
}

.sidebar-icon-custom{
  width: 1rem;
  height: 1rem;
  //background-color: var(--gray-light);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  -webkit-mask-size:contain; /* OR cover OR fixed values */

  -webkit-mask-position:center;
  -webkit-mask-repeat:no-repeat;

  mask-size:contain; /* OR cover OR fixed values */

  mask-position:center;
  mask-repeat:no-repeat;


}

.flip-h{
  &::before{
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    display: inline-block;
  }
}

//Custom Toast
.toast-custom-position {
  bottom: 2.5rem;
  right: 0;
  width: 100%;
}
.toast-container.toast-custom-position .ngx-custom-toastr {
  width: 360px;
  margin-left: auto;
  margin-right: auto;
}
.ngx-custom-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 1.25rem 1.25rem 0.75rem 3.125rem !important;
  width: 360px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  pointer-events: auto;
  box-shadow: $box-shadow !important;
  border-radius: $border-radius !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  .toast-message {
    width: 275px;
    padding-bottom: 5px;
  }
  .toast-close-button{
    top: -1.375rem;
    opacity: .8;
    font-size: 1.875rem;
    font-weight: 300;
    align-self: flex-start !important;
  }
}
.ngx-custom-toastr:hover {
  opacity: 1;
  cursor: pointer;
}
.toast-custom-title {
  font-weight: bold;
}
.toast-custom-message {
  word-wrap: break-word;
}
.toast-custom-message a:hover {
  text-decoration: underline;
}
.ngx-custom-toastr.toast-success{
  background-color: var(--success) !important
}
.ngx-custom-toastr.toast-error{
  background-color: var(--danger) !important
}
.ngx-custom-toastr.toast-info{
  background-color: var(--primary) !important
}

//scrollbar

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.overflow-scroll {
  overflow: scroll !important;

  &::-webkit-scrollbar-track
  {
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  border-radius: .5rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar
  {
  width: .7rem;
  height: .7rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar-thumb
  {
  border-radius: .5rem;
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  background-color: #555;
  }
}
.overflow-scroll-y {
  overflow-y: scroll !important;

  &::-webkit-scrollbar-track
  {
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  border-radius: .5rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar
  {
  width: .7rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar-thumb
  {
  border-radius: .5rem;
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  background-color: #555;
  }
}

.overflow-scroll-x {
  overflow-x: scroll !important;

  &::-webkit-scrollbar-track
  {
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,0.3);
  border-radius: .5rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar
  {
  height: .7rem;
  background-color: #f4f6f9;
  }

  &::-webkit-scrollbar-thumb
  {
  border-radius: .5rem;
  -webkit-box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  box-shadow: inset 0 0 .2rem rgba(0,0,0,.3);
  background-color: #555;
  }
}

//tabla custom sin bordes

.table-borderless{
  td {
    border: none;
  }
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

//striped list
.list-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

//checkbox bootstrap custom
.form-check.form-check-custom{
  margin: 0 !important;
  padding: 0 !important;
}
input.form-check-input.custom-checkbox[type="checkbox"]{
  width: 1.5rem;
  height: 1.5rem;
  accent-color: var(--primary) !important;
  position: static;
  margin: 0;
  padding: 0;
}

//hints
.hint-custom {
  position: relative;
  &-right[aria-label]:hover::before{
      content: attr(aria-label);
      background-color: #0147ba;
      color: #fff;
      padding: 4px 8px;
      border-radius: 5px;
      position: absolute;
      z-index: 9999;
      top: calc(100% + 5px);
      transform: translateX(0);
      white-space: nowrap;
  }
  &-left[aria-label]:hover::before{
      content: attr(aria-label);
      background-color: #0147ba;
      color: #fff;
      padding: 4px 8px;
      border-radius: 5px;
      position: absolute;
      z-index: 9999;
      top: calc(100% + 5px);
      transform: translateX(-100%);
      white-space: nowrap;
  }

  &-center[aria-label]:hover::before{
      content: attr(aria-label);
      background-color: #0147ba;
      color: #fff;
      padding: 4px 8px;
      border-radius: 5px;
      position: absolute;
      z-index: 9999;
      top: calc(100% + 5px);
      transform: translateX(-50%);
      white-space: nowrap;
  }

}

//fix input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf) format("truetype");
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf) format("truetype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-LightItalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf) format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf) format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf) format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: "Montserrat";
    src: url(./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf) format("truetype");
    font-weight: 900;
    font-style: italic;
}

// Mixins p/ Media queries responsive
@mixin row-to-column-reverse($minus-height){
  padding: 2rem 0 1rem 0;
  flex-direction: column-reverse;
  height: calc(100vh - $minus-height);
}

// MEDIA QUERIES RESPONSIVE

@media (max-width: 767px) {
  //oculto dropdown de user
  app-header.main-header{
    .navbar-nav#user{
      display: none;
    }
    .navbar-nav#logo{
      display: block;
    }
    background-color: #FFF;
    z-index: 0;
  }

  //brand logo
  .brand-link{
    display: none !important;
  }
  .plataforma-web{
    display: none !important;
  }

  //comportamiento sidebar
  .main-sidebar {
    &,
    &::before {
      transition: margin-right .3s ease-in-out, width .3s ease-in-out;
    }
    .sidebar-open & {
      &,
      &::before {
        margin-right: 180px;
        // margin-right: 220px;
        margin-left: auto;
      }
    }
  }
  .layout-fixed {
    .main-sidebar {
      bottom: 0;
      float: none;
      right: -220px;
      left: auto;
      position: fixed;
      top: 0;
    }

  }
  //seccion movimientos
  #movimientos-descargar-xls{
    span{
      display: none;
    }
    i{
      margin: 0 !important;
    }
  }
  #movimientos-descargar-pdf {
    span{
      display: none;
    }
    i{
      margin: 0 !important;
    }
  }

  //seccion cobrar
  .container-w-margins{
    width: 100% !important;
  }
  #card-saldo{
    background-color: transparent !important;
    box-shadow: none;
    padding: 0 !important;
    border-radius: 0;
    border-bottom: 1px solid #DDDDDD;
  }

  //seccion cobrar/pix
  #cobrar-pix-section{
    #cancelar-row-1{
      @include row-to-column-reverse(30rem);
    }
    #cancelar-row-2{
      @include row-to-column-reverse(48rem);
    }
    #mensaje-exito{
      text-align: center;
    }
  }

  //seccion cobrar/transferencia
  #transferencia{
    #cancelar-row{
      @include row-to-column-reverse(25rem);
    }
    #volver-row{
      @include row-to-column-reverse(39.5rem);
    }
    #datos-destinatario{
      border-radius: 0;
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 1.8rem;
    }
  }

  //seccion mi-cuenta
  #mi-cuenta{
    #volver-row{
      @include row-to-column-reverse(31rem);
    }
  }

  //seccion cambiar-contraseña
  #cambiar-password{
    #volver-row{
      @include row-to-column-reverse(39rem);
    }
  }

  //seccion soporte
  #soporte-header{
    flex-direction: column;
    h3:last-child{
      font-size: .8rem !important;
      margin-bottom: 0;
    }
  }


}

// @media (min-width: 768px) and (max-width: 991px) {

// }
// @media (min-width: 992px) and (max-width: 1199px) {

// }
// @media (min-width: 1200px) {

// }



// @media (max-width: 991px) {
//   .main-header .navbar-nav:first-child{
//     visibility: hidden;
//   }
//   .main-tabs{
//     visibility: visible !important;
//   }
// }
