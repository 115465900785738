//
// Component: Carousel
//

.carousel-control-custom-icon {
  .carousel-control-prev & {
    margin-left: -20px;
  }
  .carousel-control-next & {
    margin-right: 20px;
  }

  > .fa,
  > .fas,
  > .far,
  > .fab,
  > .fal,
  > .fad,
  > .svg-inline--fa,
  > .ion {
    display: inline-block;
    font-size: 40px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
    z-index: 5;
  }
}
